<template>
    <div>
        <Header :customstyle="{ background: '#0D1912' }">
            <template slot="title">支付方式</template>
        </Header>
        <div class="content">
            <div class="paytitle">支付方式</div>
            <div class="payWay">
                <div class="waycard" :style="pay_type === 2 ? {border:'1px solid rgba(39,204,127,1)'}: {}" @click="choicePay(2)">
                    <img class="payicon" :src="require('@/assets/images/mine/zhifubao.png')" />
                    <div class="txt">支付宝</div>
                </div>
                <div class="waycard" @click="choicePay(1)"  :style="pay_type === 1 ?  {border:'1px solid rgba(39,204,127,1)'} : {}">
                    <img class="payicon" :src="require('@/assets/images/mine/usdt.png')" />
                    <div class="txt">USDT</div>
               </div>
                <div class="waycard" @click="choicePay(3)"  :style="pay_type === 3 ?  {border:'1px solid rgba(39,204,127,1)'}: {}">
                    <img class="payicon" :src="require('@/assets/images/mine/card.png')" />
                   
                    <div class="txt">银行卡</div>
                </div>
            </div>
            <div class="infoContent">
                <van-field class="inputClass" v-model="username" name="姓名" label="姓名" placeholder="请输入姓名" ></van-field>
                <van-field class="inputClass" v-model="account" :name="tmpname" :label="tmpname" :placeholder="placeholder"></van-field>
            </div>
            <template v-if="pay_type<3">
                <div class="paytitle offset">收款二维码</div>
                <div class="card offset">
                    <div class="uploadcontent">
                        <van-uploader :max-size="5000 * 1024" :after-read="clickUpload" >
                            <img v-if="imgurl.length == 0" class="uploadicon" :src="require('@/assets/images/mine/uploadimg.png')"/>
                            <img v-else class="uploadicon" :src="imgurl">
                        </van-uploader>
                        <div class="txt">
                            请上传您的收款二维码图片
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="infoContent">
                    <van-field class="inputClass" v-model="bank_card_open_bank" name="开户行" label="开户行" placeholder="请输入开户行" ></van-field>
                    <van-field class="inputClass" v-model="bank_card_union_no" name="开户支行" label="开户支行" placeholder="请输入开户支行" ></van-field>
                </div>
            </template>
            <div class="paytitle offset">安全密码</div>
            <div class="infoContent">
            <van-field class="inputClass" type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码" ></van-field>
            </div>
            <div class="submit">
                <div class="submitbtn" @click="clickSubmit">
                    保存
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import Header from "../../components/header.vue";
import {addPayWay, uploadImg,getUserInfo} from '@/request/api'
export default {
    components: {
        Header
    },
    data() {
        return {
            pay_type: 0,
            username:'',
            account: '',
            placeholder: '请输入支付宝账号',
            tmpname: '支付宝账号',
            password: '',
            imgurl: 'https://img01.yzcdn.cn/vant/leaf.jpg',
            bank_card_union_no:'',
            bank_card_open_bank:'',
            bank_card_no:'',
            address:'',
            userInfo:{},
            tradeInfo:{},
        }
    },
    activated () {
        this.address = sessionStorage.getItem("address");
        if(this.address){
            this.getUserInfo();
        }
        this.pay_type = 0
        this.username = ''
        this.account = ''
        this.password = ''
        this.imgurl = 'https://img01.yzcdn.cn/vant/leaf.jpg'

    },
    methods:{
        getUserInfo(){
            getUserInfo({address:this.address}).then((res)=> {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("level_id");
                sessionStorage.setItem("user", JSON.stringify(res.data));
                sessionStorage.setItem("level_id", res.data.level_id);
                console.log(sessionStorage.getItem("user"))
                this.userInfo = res.data;
            })
        },
        choicePay(num) {
            this.pay_type = num
            if(this.pay_type == 1){
                this.placeholder = '请输入币安链收USDT地址'
                this.tmpname = 'USDT'
            }else if (this.pay_type === 2){
                this.placeholder = '请输入支付宝账号'
                this.tmpname = '支付宝账号'
            }else if (this.pay_type === 3){
                this.placeholder = '请输入银行卡账号'
                this.tmpname = '银行卡账号'
            }
        },
        clickUpload (file) {
            const fd = new FormData()
            fd.append('file', file.file)
            uploadImg(fd).then(res=>{
                this.imgurl = res.data.http_url
            })
        },
        clickSubmit () {
            let params;
            let _this = this;
            if(this.address&&this.userInfo.has_paypwd!=1){
                Toast('请先完善资料,设置安全密码');
                setTimeout(function () {
                    _this.$router.push({
                        path:'/home/mine'
                    })
                })
                return
            }
            if(!this.address){
                Toast('请使用币安智能链链接')
                return
            }
            if (this.pay_type === 0){
                 Toast('请选择支付方式')
                return
            }
            if (this.username.length === 0) {
                Toast('请输入姓名')
                return
            }
            if (this.account.length === 0) {
                Toast('请输入账号')
                return
            }
            if (this.password.length === 0) {
                Toast('请输入资金密码')
                return
            }
            if(this.pay_type<3){
                if (this.imgurl.length === 0) {
                    Toast('请上传收款二维码')
                    return
                }
                params = {
                    address: this.address,
                    pay_type: this.pay_type,
                    pay_code: this.password,
                    username: this.username,
                    wx_account: this.pay_type === 1 ? this.account : '',
                    alipay_account: this.pay_type === 2 ? this.account : '',
                    wx_img: this.pay_type === 1 ? this.imgurl : '',
                    ali_img: this.pay_type === 2 ? this.imgurl : ''
                }
            }
            else{
                if (this.bank_card_open_bank.length === 0) {
                    Toast('请输入开户行');
                    return
                }
                if (this.bank_card_union_no.length === 0) {
                    Toast('请输入开户支行');
                    return
                }
                params = {
                    address: this.address,
                    pay_type: this.pay_type,
                    pay_code: this.password,
                    username: this.username,
                    bank_card_no: this.account ,
                    bank_card_union_no: this.bank_card_union_no ,
                    bank_card_open_bank: this.bank_card_open_bank ,
                }
            }
            addPayWay(params).then(res => {
                console.log(res)
                if(res.code==200) {
                    Toast('添加成功');
                    this.$router.back()
                }else if(res.code==100001){
                    Toast('安全密码错误');
                    this.$router.back()
                }
                else if(res.code==100003){
                    Toast('实名认证姓名必须与支付方式姓名一致');
                    this.$router.back()
                }
                else{
                    Toast(res.msg);
                    this.$router.back()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.offset {
    margin-top:32px;
}
.content {
    background: #0C1913;
    min-height: 100vh;
    padding: 32px;
    .paytitle {
        opacity: 1;
        color: rgba(214,255,227,1);
        font-size: 32px;
        font-weight: 400;
        font-family: "PingFang SC";

    }
    .payWay {
        display: flex;
        justify-content: space-between;
        margin-top:16px;
        .waycard {
            width: 188px;
            height: 72px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(41,51,45,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            color: rgba(214,255,227,1);
            display: flex;
            align-items: center;
            justify-content: center;
            .payicon {
                width: 40px;
                height: 40px;
            }
            .txt {
                margin-left: 10px;
                color: rgba(139,166,149,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
            }
        }
        
    }
    .infoContent {
        margin-top:32px;
        .inputClass {
            background: #29332D;
            ::v-deep .van-field__control{
                color: white;
            }
        } 
    }
    .card {
        width: 694px;
        height: 244px;
        border-radius: 16px;
        opacity: 1;
        background: rgba(41,51,45,1);
        .uploadcontent {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .uploadicon {
                width: 150px;
                height: 150px;
            }
            .txt {
                opacity: 1;
                color: rgba(139,166,149,1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 36px;
            }
        }
    }
    .submit {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        .submitbtn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39,204,127,1);
            color: rgba(32,40,35,1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
        }
    }
}
</style>